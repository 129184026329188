.matchup-terms-of-use {
    width: 100%;
    padding-top: 32px;
    height: calc(100vh - 52px);
    max-width: var(--max-width);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    h1 {
        font-size: 32px;
        line-height: 40px;
        margin-bottom: 10px;
        color: var(--header1-color);
    }

    .last-updated {
        font-size: 18px;
        font-weight: 500;
        margin-bottom: 36px;
        color: var(--last-updated-color);
    }

    h2 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 24px;
        color: var(--header2-color);
        font-weight: 700;
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
        color: var(--header3-color);
        margin-bottom: 20px;
    }

    span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
        color: var(--span-color);

        em {
            margin-bottom: 0px;
        }

        strong {
            font-size: 14px;
        }
    }

    strong {
        font-weight: 600;
    }

    ul {
        padding-block: 24px;

        li {
            list-style: inside;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0px;
            }

            span {
                margin-bottom: 0px;
                display: inline;
            }

            &::marker {
                color: var(--span-color);
            }
        }
    }

    u {
        cursor: pointer;
    }

    a {
        color: var(--link-color);
        cursor: pointer;
    }

    em {
        display: inline-block;
        font-size: 14px;
        font-weight: 600;
        font-weight: bold;
        color: var(--span-color);
        margin-bottom: 20px;
    }

    section {
        margin-bottom: 40px;
    }

    table {
        margin-block: 24px;
        border-collapse: collapse;

        span {
            margin-bottom: 0px;
            font-size: 13px;
        }

        tr {
            display: flex;
            flex-direction: row;

            th {
                height: 42px;
                min-height: 42px;
                min-width: 80px;
                display: flex;
                align-items: center;
                padding: 6px;
                border: 1px solid rgb(32,32,32);

                &:not(:first-child) {
                    border-left: none;
                }

                &:nth-child(1) {
                    flex: 6;
                }

                &:nth-child(2) {
                    flex: 12;
                }

                &:nth-child(3) {
                    flex: 3;
                }
            }

            td {
                min-width: 80px;
                min-height: 60px;
                display: flex;
                align-items: center;
                border: 1px solid rgb(32,32,32);
                padding: 6px;
                border-top: none;

                &:not(:first-child) {
                    border-left: none;
                }

                &:nth-child(1) {
                    flex: 6;
                }

                &:nth-child(2) {
                    flex: 12;
                }

                &:nth-child(3) {
                    flex: 3;
                    justify-content: center;
                }
            }
        }
    }

    #tableofcontents {
        a {
            display: block;
            margin-bottom: 2px;

            &:hover {
                text-decoration: underline;
                font-weight: bold;
            }
        }
    }
}

:root {
    --last-updated-color: rgb(60,60,60);
    --header1-color: rgb(24, 24, 24);
    --header2-color: rgb(42, 42, 42);
    --header3-color: rgb(42, 42, 42);
    --span-color: rgb(100, 100, 100);
    --link-color: rgb(1, 94, 255);

}