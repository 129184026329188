.matchup-help {
    width: 100%;
    padding-top: 32px;
    height: calc(100vh - 52px);
    max-width: var(--max-width);
    display: flex;
    flex-direction: column;
    overflow-y: scroll;

    h1 {
        font-size: 36px;
        color: black;
        margin-bottom: 10px;

        strong {
            font-size: 36px;
            color: var(--matchup-theme-color);
        }
    }

    .helptext {
        font-size: 15px;
        margin-bottom: 60px;

        a {
            font-size: 15px;
            color: rgb(0, 94, 255);
        }
    }

    h2 {
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 24px;
        color: var(--header2-color);
        font-weight: 700;
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
        color: var(--header3-color);
        margin-bottom: 20px;
    }

    span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        margin-bottom: 20px;
        color: var(--span-color);

        em {
            margin-bottom: 0px;
        }

        strong {
            font-size: 14px;
        }
    }

    ul {
        padding-block: 24px;

        li {
            list-style: inside;
            margin-bottom: 8px;

            &:last-child {
                margin-bottom: 0px;
            }

            span {
                margin-bottom: 0px;
                display: inline;
            }

            &::marker {
                color: var(--span-color);
            }
        }
    }

    a {
        color: var(--link-color);
        cursor: pointer;
    }

    section {
        margin-bottom: 40px;
    }

    #tableofcontents {
        a {
            display: block;
            margin-bottom: 2px;

            &:hover {
                text-decoration: underline;
                font-weight: bold;
            }
        }
    }
}

