@mixin max500 {
    @media screen and (max-width: 500px) {
        @content
    }
}

#matchup {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    // background: linear-gradient(180deg, rgba(255,56,139,1) 0%, rgba(255,11,55,1) 100%);

    .mobile-menu {
        position: fixed;
        width: 100vw;
        height: 100vh;


        .matchup-f11 {
            position: absolute;
            width: calc(100vw - var(--padding-inline-mobile) * 2);
            top: 60px;
            left: var(--padding-inline-mobile);
            background-color: #fff;
            border-radius: 16px;
            padding: var(--padding-inline-mobile);
            display: flex;
            flex-direction: column;
            border: 1px solid rgb(240, 240, 240);
            animation: menuAnim .2s ease;
            
            .matchup-f12 {
                width: 100%;
                height: 42px;
                border-radius: 12px;
                display: flex;
                align-items: center;
                padding-inline: var(--padding-inline-mobile);
                cursor: pointer;
                
                span {
                    font-size: 16px;
                    font-weight: 500;
                    color: rgb(52, 52, 52);
                }

                &:hover {
                    background-color: rgb(240, 240, 240);
                }
            }

            .matchup-f13 {
                width: 100%;
                height: 42px;
                margin-top: 8px;
                border-radius: 12px;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: var(--matchup-theme-color);
                cursor: pointer;

                &:hover {
                    background-color: var(--matchup-theme-hover-color);
                }
                
                span {
                    font-family: "barlow";
                    color: white;
                    font-size: 20px;
                    font-weight: bold;
                }

            }

        }
    }

    .matchup-navbar {
        width: 100vw;
        height: 52px;
        min-height: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 0px;
        left: 0px;
        background-color: white;

        .matchup-navbar-container {
            width: 100vw;
            max-width: var(--max-width);
            display: flex;
            flex-direction: row;
            align-items: center;

            .matchup-logo {
                width: 36px;
                cursor: pointer;
            }

            .matchup-menu {
                display: none;
                width: 36px;
                height: 36px;
                align-items: center;
                justify-content: center;
                background-color: rgb(240, 240, 240);
                border-radius: 8px;
                cursor: pointer;

                &:hover {
                    background-color: rgb(220,220,220);
                }


                svg {
                    color: #000;
                }

                @include max500 {
                    display: flex;
                }
            }

            li {
                margin-inline: 16px;
                display: flex;
                flex-direction: row;

                @include max500 {
                    display: none;
                }

                a {
                    padding-inline: 12px;
                    padding-block: 8px;
                    margin-right: 4px;
                    align-items: center;
                    border-radius: 8px;
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;

                    &:hover {
                        background: rgb(240, 240, 240);
                    }

                    &:last-child {
                        margin-right: 0px;
                    }

                    span {
                        font-size: 14px;
                        color: rgb(42, 42, 42);
                        font-family: "roboto";
                        font-weight: 500;
                    }
                }
            }

            .download-button {
                padding-inline: 16px;
                padding-block: 4px;
                border-radius: 50px;
                background-color: white;
                border: 1px solid var(--matchup-theme-color);
                cursor: pointer;

                @include max500 {
                    display: none;
                }

                &:hover {
                    background-color: rgb(245, 245, 245);
                }

                span {
                    font-family: "barlow";
                    font-size: 18px;
                    font-weight: bold;
                    color: var(--matchup-theme-color);

                }
            }
        }
    }

    .matchup-content {
        width: 100vw;
        height: calc(100vh - 52px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 52px;
    }

    
}

@keyframes menuAnim {
    from {
        top: 42px;
        opacity: 0;
    }
    to {
        top: 60px;
        opacity: 1;
    }
}