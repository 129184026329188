*, ::before, ::after{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: "roboto";
    font-size: 14px;
    letter-spacing: 0px !important;
    scroll-behavior: smooth;
  }
  
  *::-webkit-scrollbar {
    display: none;
  }
  
  html, body, #root {
    min-width: 100vw;
    min-height: 100vh;
    line-height: 1.5;
    background-color: var(--background-color);
    -webkit-tap-highlight-color: rgba(0,0,0,0);
    -webkit-touch-callout: none;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  input[type="file"] {
    display: none;
  }
  
  .app {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  .danger {
    color: var(--color-danger);
    background: rgba(var(--color-danger-rgb), .1);
  }
  
  .warning {
    color: var(--color-warning);
    background: rgba(var(--color-warning-rgb), .05);
  }
  
  .info {
    color: var(--color-info);
    background: rgba(var(--color-info-rgb), .1);
  }
  
  .success {
    color: var(--color-success);
    background: rgba(var(--color-success-rgb), .1);
  }
  
  :root {
    /* --theme-color:blue;
    --theme-color-rgb: 0, 0, 200;
    --theme-hover-color:  blue; */
    // --theme-color:rgb(114, 120, 94);
    // --theme-color-rgb: 114, 120, 94;
    // --theme-hover-color:  rgb(94, 100, 74);
    --max-width: 1200px;
    --background-color: white;
    --theme-color: rgb(5,103,210);
    --matchup-theme-color: #ff0a37;
    --matchup-theme-hover-color: #dc0d32;
    --matchup-theme-color-rgb: 255, 10, 55;
    --theme-color-rgb: 5, 103, 210;
    --theme-hover-color: #5034a4;
    --secondary-color: rgb(100, 75, 180);
    /* --brand-color: #504136; */
    --brand-color: #ffd300;
    --brand-color-rgb: 240, 245, 250;
    /*******************************/
    --color-warning: rgb(238, 210, 2);
    --color-warning-rgb: 238, 210, 2;
    --color-danger: rgb(187,33,36);
    --color-danger-rgb: 187,33,36;
    --color-info: rgb(91,192,222);
    --color-info-rgb: 91,192,222;
    --color-success: rgb(34,187,51);
    --color-success-rgb: 34, 187, 51;
    --color-unimportant: rgb(170,170,170);
    --color-unimportant-rgb: 170,170,170;
    /*******************************/
    --border-color: rgb(230,230,230);
    --color-black-smooth: rgb(60,60,60);
    --color-white-smooth: rgb(235,235,235);
    --color-grey-smooth: rgb(205,205,205);
    --color-gray-storm: #697488;
    --color-modal-bg: rgba(24, 17, 45, 0.72);;
    --break-point: 1400px;
    --padding-inline-desktop: 20px;
    --padding-inline-mobile: 12px;
    --padding-up-desktop: 60px;
    --padding-up-mobile: 40px;
  }
  
  a {
    text-decoration: none;
    color: inherit;
  }
  
  li {
    text-decoration: none;
    list-style: none;
  }
  
  button {
    border: none;
    outline: none;
    background: rgba(255,255,255,0);
    cursor: pointer;
  }
  
  select {
    border: none;
    outline: none;
    background: rgba(255,255,255,0);
    -webkit-appearance: none;
    -moz-appearance: none;
    text-indent: 1px;
    text-overflow: '';
  }
  
  input[type="search"]::-webkit-search-decoration,
  input[type="search"]::-webkit-search-cancel-button,
  input[type="search"]::-webkit-search-results-button,
  input[type="search"]::-webkit-search-results-decoration {
    -webkit-appearance:none;
  }
  
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
  
  .noselect {
    -webkit-touch-callout: none; /* iOS Safari */
      -webkit-user-select: none; /* Safari */
       -khtml-user-select: none; /* Konqueror HTML */
         -moz-user-select: none; /* Old versions of Firefox */
          -ms-user-select: none; /* Internet Explorer/Edge */
              user-select: none; /* Non-prefixed version, currently
                                    supported by Chrome, Edge, Opera and Firefox */
  }
  
  .row {
    display: flex;
  }
  
  .col {
    display: flex;
    flex-direction: column;
  }
  
  .ai-center {
    align-items: center;
  }
  
  .space {
    flex: 1;
  } 
  
  .with-scroll {
    &:hover {
        &::-webkit-scrollbar-thumb {
            background: rgb(120, 120, 120);
            background-clip: content-box;
        }
    }
    
    &::-webkit-scrollbar {
        width: 4px;
        display: block;
    }
    
    &::-webkit-scrollbar-track {
        background: rgb(230, 230, 230);
        border-radius: 10px;
        background-clip: content-box;
        border: 1px solid transparent;
    }
    
    &::-webkit-scrollbar-thumb {
        background: silver;
        border-radius: 10px;
        background-clip: content-box;
        transition: background .3s ease;
    }
  }
  
 .center-all {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dropdown {
    z-index: 1000;
  }
  
  .overlay {
    width: 100vw;
    height: 100vh;
    background: rgba(255,255,255,0);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
  }
  
  .modal-bg {
    width: 100vw;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    background: var(--color-modal-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    animation: modal-bg-anim .3s ease;
    z-index: 1000;
  }
  
  .modal {
    width: 100%;
    max-width: 450px;
    background: white;
    border-radius: 10px;
    transform-origin: 50% 50%;
    animation: modal-anim .3s ease;
    transition: scale .2s ease;
    overflow: hidden;
  }
  
  .modal-container {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 30px;
  }

  .padding-inline {
    padding-inline: var(--padding-inline-desktop);
  }
  
  @keyframes modal-bg-anim {
    from {
      background: rgba(255,255,255,0);
    } to {
      background: var(--color-modal-bg);
    }
  }
  
  @keyframes modal-anim {
    from {
      scale: .3;
    } to {
      scale: 1;
    }
  }
  
  
  @media screen and (max-width: 500px) {
    .padding-inline {
      padding-inline: var(--padding-inline-mobile);
    }
    
    .modal {
      width: 100% !important;
      height: 100% !important;
      border-radius: 0px !important;
      max-width: 100vw !important;
  
      .modal-container {
        padding: 20px;  
      }
    }
  }
  

  @font-face {
    font-family: barlow;
    src: url(../src/assets/fonts/barlow/barlow_condensed_400.ttf);
    font-style: normal;
    font-weight: 400;
  }
  
  @font-face {
    font-family: barlow;
    src: url(../src/assets/fonts/barlow/barlow_condensed_700.ttf);
    font-style: normal;
    font-weight: 700;
  }

  @font-face {
    font-family: roboto;
    src: url(../src/assets/fonts/roboto/Roboto-200.ttf);
    font-weight: 200;
  }
  
  @font-face {
    font-family: roboto;
    src: url(../src/assets/fonts/roboto/Roboto-400.ttf);
    font-weight: 400;
  }
  
  @font-face {
    font-family: roboto;
    src: url(../src/assets/fonts/roboto/Roboto-500.ttf);
    font-weight: 500;
  }
 
  @font-face {
    font-family: roboto;
    src: url(../src/assets/fonts/roboto/Roboto-600.ttf);
    font-weight: 600;
  }
 
  @font-face {
    font-family: roboto;
    src: url(../src/assets/fonts/roboto/Roboto-700.ttf);
    font-weight: 700;
  }
 