.matchup-content-container {
    width: 100%;
    height: calc(100vh - 122px);
    padding-block: 16px;
    max-width: var(--max-width);
    overflow: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .matchup-c10 {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 20px;
        max-width: 720px;
        text-align: center;

        .matchup-c12 {
            font-family: "roboto";
            font-weight: 700;
            font-size: 42px;
            color: black;
            line-height: 46px;
            margin-bottom: 10px;

            span {
                line-height: 46px;
                font-family: "roboto";
                font-weight: 700;
                font-size: 42px;
                color: var(--matchup-theme-color);
            }
        }

        .matchup-c13 {
            font-size: 16px;
            color: rgb(80, 80, 80);
        }
    }

    .matchup-c11 {
        height: 42px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-bottom: 32px;

        .matchup-c14 {
            height: 100%;
            min-height: 42px;
            padding-inline: 20px;
            border-radius: 50px;
            background-color: var(--matchup-theme-color);
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-right: 8px;
            cursor: pointer;

            &:hover {
                background-color: var(--matchup-theme-hover-color);
            }

            &:last-child {
                margin-right: 0px;

                img {
                    padding-bottom: 4px;
                }
            }

            img {
                width: 16px;
                margin-right: 8px;
            }

            span {
                font-size: 16px;
                color: white;
                font-weight: 500;
                font-family: "roboto";
            }


        }

       
    }

    img {
        width: calc(100vh/4);
        max-width: 360px;
    }
}

footer {
    width: 100vw;
    height: 70px;
    padding-block: 12px;
    background-color: rgb(240,240,240);

    .footer-container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .footer-links {
            width: 100%;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            margin-bottom: 4px;
            gap: 16px;

            span {
                font-size: 12px;
                cursor: pointer;
                color: rgb(140, 140, 140);
                &:hover {
                    color: var(--matchup-theme-color);
                }
            }
        }

        span {
            // color: var(--matchup-theme-color);
            font-size: 16px;
            color: rgb(80, 80, 80);
            font-weight: 500;
        }
    }
}